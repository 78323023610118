// initial state
const state = () => ({
  allInOne: "1",
  cart: [],
});

// getters
const getters = {};

/* EXAMPLE actions (非同期処理が可能)*/
const actions = {
  //   async getAllProducts({ commit }) {
  //     const products = await shop.getProducts();
  //     commit('setProducts', products);
  //   },
};

/* EXAMPLE mutations (同期処理のみ)*/

const mutations = {
  //   setProducts(state, products) {
  //     state.all = products;
  //   },

  //   decrementProductInventory(state, { id }) {
  //     const product = state.all.find(product => product.id === id);
  //     product.inventory--;
  //   },

  addToCart(state, product_obj) {
    /* change sku_number */
    if (state.cart.find(({ sku_id }) => sku_id === product_obj.sku_id)) {
      state.cart = state.cart.map((item) =>
        item.sku_id === product_obj.sku_id
          ? { ...product_obj, sku_number: item.sku_number + product_obj.sku_number }
          : item,
      );
    } else {
      /* add new product */
      state.cart.push(product_obj);
    }
  },

  setMyCart(state, data) {
    state.cart = data;
  },

  setAllInOne(state, data) {
    state.allInOne = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
