import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from '../store/index.js';

dayjs.locale('ja');
Vue.prototype.$dayjs = dayjs;
Vue.config.productionTip = false;
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
