// initial state
const state = () => ({
  login_redirect: '/',

  /* Authentication */
  logged_in: false,
  user_id: '',
  login_state: 'state123', //これってlocal storageに保存していいの？？？
  friendship: false,
  is_corporation: false,
  sku_model: [],
  home_scrollTop: 0,
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  set_home_scrollTop(state, params) {
    state.home_scrollTop = params.scrollTop
  },
  set_sku_model(state, params) {
    state.sku_model = params.sku_model;
  },
  login_usr(state, params) {
    console.log('logged_in', params.usr_id);
    state.user_id = params.user_id;
    state.friendship = params.friendship;
    state.is_corporation = params.is_corporation;
    state.logged_in = true;
  },
  logout(state) {
    console.log(state.logged_in);
    console.log('logout vuex clicked');
    state.user_id = '';
    state.logged_in = false;
    console.log(state.logged_in);
  },
  set_login_redirect(state, params) {
    state.login_redirect = params.login_redirect;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
