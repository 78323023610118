<template>
  <v-app>
    <!-- color="orange darken-2" -->
    <v-app-bar app class="white" elevation="1" style="max-width: 600px; margin: 0 auto">
      <!-- @click="redirect" -->
      <v-row>
        <!-- <v-spacer/> -->

        <v-col v-if="showGoBackIcon" cols="4" class="pl-0">
          <v-btn icon @click="goBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col v-else cols="4" class="pl-3">
          <v-btn icon @click="redirect('')">
            <!-- <strong>レコセレ</strong> -->
            <v-icon>mdi-home-outline</v-icon>
            <!-- <v-img src="@/assets/favicon.png"></v-img> -->
          </v-btn>
        </v-col>

        <v-col cols="2" class="pa-0">
          <!-- <SpotsComponent /> -->
        </v-col>

        <v-col cols="2" class="caption"></v-col>
        <v-col cols="2" class="caption">
          <!-- <p>{{this.$store.state.cart.length}}</p> -->
          <!-- <v-badge color="deep-orange" dot overlap v-if="this.$store.state.cart.length > 0">
            <CartComponent />
          </v-badge>
          <CartComponent v-if="this.$store.state.cart.length == 0" /> -->
          <v-btn icon @click="redirect('search/product')">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" class="caption">
          <v-btn icon @click="redirect('message/messageList')">
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <keep-alive include="Home,SearchProducts">
        <router-view />
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
  // import CartComponent from "@/components/Cart.vue";
  // import BottomNavigation from "@/components/BottomNavigation.vue";
  export default {
    name: 'App',
    metaInfo: {
      title: 'レコセレ公式',
      titleTemplate: '%s | ワンランク上の丁寧な暮らし',
      meta: [
        {
          name: 'description',
          content: 'コストコの商品をLINEで注文、近所のいつものお店で受取。',
        },
      ],
    },
    data: () => ({
      sheet: false,
      dialog: false,
      tab: 0,
      showGoBackIcon: false,
    }),
    created() {
      console.log('login_state', this.$store.state.user.login_state);
      /* uncomment for development environment */
      if (process.env.NODE_ENV != 'production') {
        this.$store.commit('user/login_usr', {
          user_id: '63c8b4b87113883087493254',
          friendship: true,
          is_corporation: false,
          is_adult: true,
        });
      }
    },
    components: {
      // CartComponent,
      // BottomNavigation
    },
    beforeUpdate() {
      this.showGoBackIcon =
        this.$route.path.split('/').length > 2 || this.$route.path.startsWith('/pay');
    },
    methods: {
      redirect: function (params) {
        this.$router.push('/' + params);
      },
      goBack: function () {
        switch (this.$route.name) {
          case 'Product':
            this.$router.push('/');
            break;
          case 'SearchProducts':
            this.$router.push('/');
            break;
          case 'SearchProductById':
            this.$router.push('/search/product');
            break;
          case 'BrandsProducts':
            this.$router.push('/brands');
            break;
          case 'FeaturesProducts':
            this.$router.push('/features');
            break;
          case 'FeaturesProductsById':
            this.$router.push('/features/' + this.$route.params.tag_id);
            break;
          case 'BrandsProductById':
            this.$router.push('/brands/' + this.$route.params.brand_id);
            break;
          case 'MyPageCorporationOrderHistory':
            this.$router.push('/mypage');
            break;
          case 'MyPageOrderHistory':
            this.$router.push('/mypage');
            break;
          case 'MyPageReturnOrderHistory':
            this.$router.push('/mypage');
            break;
          case 'MyPageCouponList':
            this.$router.push('/mypage');
            break;
          case 'MyPagePointHistory':
            this.$router.push('/mypage');
            break;
          case 'MyPageMyCouponCode':
            this.$router.push('/mypage');
            break;
          case 'MyPayResult':
            this.$router.push('/mypage/order-history');
            break;
          case 'MyPayReturnResult':
            this.$router.push('/mypage/return-order-history');
            break;
          case 'CartAdult':
            this.$router.push('/cart');
            break;
          case 'Pay':
            this.$router.push('/cart');
            break;
          case 'SpotById':
            this.$router.push('/spots');
            break;
          default:
            this.$router.push('/');
            break;
        }
      },
      cart: function () {
        this.$router.push('/cart');
      },
      pay: function () {
        this.dialog = false;
        this.$router.push('/pay');
        // this.$store.commit("set_pay", { groupOrder_id: id });
        // if (this.$store.state.logged_in) {

        // } else {
        //   this.$store.commit("set_login_redirect",{login_redirect:"/pay"});
        //   this.$router.push("/login");
        // }
      },
    },
  };
</script>

<!-- <style>
  @import '../node_modules/loaders.css/loaders.min.css';
</style> -->
<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  html,
  body {
    overflow-x: hidden;
  }

  .myClass:focus::before {
    opacity: 0 !important;
  }
</style>
