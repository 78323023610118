import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import cart from './modules/cart.js';
import user from './modules/user.js';
import brand from './modules/brand.js';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // bottomBarValue: 'home',
  },
  mutations: {},

  plugins: [createPersistedState()],
  modules: {
    cart,
    user,
    brand
  },
  // plugins: [
  //   createPersistedState({
  //     key: 'recosele_session',
  //     paths: ['cart'],
  //     storage: window.sessionStorage,
  //   }),
  //   createPersistedState({
  //     key: 'recosele_local',
  //     paths: ['user'],
  //     storage: window.localStorage,
  //   }),
  // ],
});
