import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import store from '../../store/index.js';

Vue.use(VueRouter);
Vue.use(
  VueMeta,
  // ,{refreshOnceOnNavigation:true}
);

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

const routes = [
  {
    path: '/',
    name: 'End',
    component: lazyLoad('End'),
  },
  {
    path: '/product/:product_id',
    name: 'Product',
    component: lazyLoad('Home/Product'),
  },
  {
    path: '/search/product',
    name: 'End',
    component: lazyLoad('End'),
  },
  {
    path: '/search/product/:product_id',
    name: 'End',
    component: lazyLoad('End'),
  },
  {
    path: '/brands',
    name: 'End',
    component: lazyLoad('End'),
  },
  {
    path: '/features',
    name: 'End',
    component: lazyLoad('End'),
  },
  {
    path: '/brands/:brand_id',
    name: 'End',
    component: lazyLoad('End'),
  },
  {
    path: '/features/:tag_id',
    name: 'End',
    component: lazyLoad('End'),
  },
  {
    path: '/features/:tag_id/:product_id',
    name: 'End',
    component: lazyLoad('End'),
  },
  {
    path: '/brands/:brand_id/:product_id',
    name: 'End',
    component: lazyLoad('End'),
  },
  {
    path: '/mypage',
    name: 'MyPage',
    component: lazyLoad('MyPage/MyPage'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mypage/corporation-order-history',
    name: 'MyPageCorporationOrderHistory',
    component: lazyLoad('MyPage/CorporationOrderHistory'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mypage/order-history',
    name: 'MyPageOrderHistory',
    component: lazyLoad('MyPage/OrderHistory'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mypage/return-order-history',
    name: 'MyPageReturnOrderHistory',
    component: lazyLoad('MyPage/ReturnOrderHistory'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mypage/return-order-history/:payment_id',
    name: 'MyPayReturnResult',
    component: lazyLoad('Cart/PayResult'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mypage/order-history/:payment_id',
    name: 'MyPayResult',
    component: lazyLoad('Cart/PayResult'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mypage/coupon-list',
    name: 'MyPageCouponList',
    component: lazyLoad('MyPage/CouponList'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mypage/point-history',
    name: 'MyPagePointHistory',
    component: lazyLoad('MyPage/PointHistory'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mypage/my-coupon-code',
    name: 'MyPageMyCouponCode',
    component: lazyLoad('MyPage/MyCouponCode'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cart',
    name: 'End',
    component: lazyLoad('End'),
  },
  {
    path: '/cart/adult',
    name: 'End',
    component: lazyLoad('End'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pay',
    name: 'End',
    component: lazyLoad('End'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pay/result/:payment_id',
    name: 'End',
    component: lazyLoad('End'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/message/messageList',
    name: 'End',
    component: lazyLoad('End'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/articles/rules',
    name: 'Rules',
    component: lazyLoad('Articles/Rules'),
  },
  {
    path: '/articles/privacy',
    name: 'Privacy',
    component: lazyLoad('Articles/Privacy'),
  },
  {
    path: '/articles/terms',
    name: 'Terms',
    component: lazyLoad('Articles/Terms'),
  },
  {
    path: '/articles/qa',
    name: 'QA',
    component: lazyLoad('Articles/QA'),
  },
  {
    path: '/login',
    name: 'Login',
    component: lazyLoad('Login'),
  },
  {
    path: '/spots',
    name: 'End',
    component: lazyLoad('End'),
  },
  {
    path: '/spots/:spot_id',
    name: 'End',
    component: lazyLoad('End'),
  },
  // {
  //   path: "/deal/:id",
  //   name: "Deal",
  //   component: lazyLoad('Deal')
  // },
  {
    path: '/error',
    name: 'Error',
    component: lazyLoad('Error'),
  },

  {
    path: '/redirect',
    name: 'Redirect',
    component: lazyLoad('Redirect'),
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // window.scrollTo(0, 0);
  // console.log('router from', from);
  if (to.meta.scrollHeight) {
    setTimeout(() => {
      document.documentElement.scrollTop = to.meta.scrollHeight;
    });
  } else {
    setTimeout(() => {
      document.documentElement.scrollTop = 0;
    });
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log(from.path);
    if (from?.path) {
      store.commit('user/set_login_redirect', { login_redirect: from.path });
    }
    console.log(store.state.user.logged_in)
    if (!store.state.user.logged_in) {
      next({ name: 'Login' });
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

// const DEFAULT_TITLE = 'コスパの殿堂ShareMart';
// router.afterEach((to) => {
//     // Use next tick to handle router history correctly
//     // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
//     Vue.nextTick(() => {
//         document.title = to.meta.title || DEFAULT_TITLE;
//     });
// });

export default router;
